// @flow
import React, { Component, Fragment } from "react";
import { Menu, Modal, Button, Container, Loader } from "semantic-ui-react";
import xlsx from "json-as-xlsx";
import { leadersRoles } from "constantes/leaders";

type Props = {
    consumers: any,
    children: any,
    disabled: boolean,
};

type State = {
    csvHeader: any,
    modal: boolean,
};

/**
 * XlsxItem
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description XlsxItem component
 */
class XlsxItem extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            modal: false,
            csvHeader: [
                { label: "Siège", value: "etablissementSiege" },
                { label: "Siret", value: "siret" },
                { label: "Siren", value: "siren" },
                { label: "IDCC", value: "idcc" },
                { label: "Dénomination", value: "denomination" },
                { label: "Adresse", value: "geoAdresse" },
                { label: "Code postal", value: "codePostal" },
                { label: "Ville", value: "libelleCommune" },
                { label: "Representants", value: "representants" },
                {
                    label: "Tranche d'effectif salarié",
                    value: "trancheEffectifs",
                },
                { label: "Code APE", value: "activitePrincipaleAPE" },
                { label: "Activité", value: "classe" },
                {
                    label: "Catégorie juridique",
                    value: "natureJuridique",
                },
                {
                    label: "Catégorie d'entreprise",
                    value: "categorieEntreprise",
                },
                { label: "Date de création", value: "dateCreation" },
                { label: "Téléphone", value: "phone" },
                { label: "E-mail", value: "emails" },
                { label: "Site internet", value: "website" },
                { label: "Réseaux sociaux", value: "socials" },
            ],
        };
    }

    formateCsvData = async () => {
        /**
         * @instance
         * @async
         * @method formateCsvData
         * @memberof Containers.Companies
         * @description Format csv data
         * @return { Object }
         */

        const { companies } = this.props.consumers;
        const etablissements = await companies.getLocalCompanies();
        return etablissements.map((item) => {
            return {
                ...item.etablissement,
                ...item.uniteLegale,
                representants: item.representants,
                denomination: item.uniteLegale.denomination
                    ? item.uniteLegale.denomination
                    : item.uniteLegale.identite,
                emails:
                    item.knowledge && item.knowledge.emails
                        ? item.knowledge.emails.join(",")
                        : "",
                phone:
                    item.knowledge && item.knowledge.phone
                        ? item.knowledge.phone
                        : "",
                website:
                    item.knowledge && item.knowledge.website
                        ? item.knowledge.website
                        : "",
                socials:
                    item.knowledge && item.knowledge.socials
                        ? item.knowledge.socials
                            .map(
                                (element) =>
                                    `${element.name} : ${element.link}`
                            )
                            .join(",")
                        : "",
                siret: item.siret,
                siren: item.siren,
                idcc: item.ccn && item.ccn.length ? item.ccn.map((elm) => `${elm.idcc} - ${elm.libelleidcc}`).join(',') : "",
                trancheEffectifs: item.uniteLegale.trancheEffectifsLabel
                    ? item.uniteLegale.trancheEffectifsLabel
                    : "inconnu",
                etablissementSiege: item.etablissement.etablissementSiege,
                geoAdresse: `${item.geoAdresse} ${item.adresse.codePostal} ${item.adresse.libelleCommune}`,
                codePostal: item.adresse.codePostal,
                libelleCommune: item.adresse.libelleCommune,
                dateCreation: item.dateCreation,
            };
        });
    };

    download = async () => {
        const data = await this.formateCsvData();
        xlsx(
            this.state.csvHeader,
            data,
            {
                sheetName: `Entreprises`,
                fileName: `entreprises`,
                extraLength: 3,
                writeOptions: {},
            },
            true
        );
    };

    refactorInpiData = (companie: any) => {
        /**
         * @instance
         * @async
         * @method refactorInpiData
         * @memberof Containers.CustomComponent
         * @description Format and representants
         * @return { Object }
         */
        let representants = "";
        if (companie.typePersonne === "P") {
            representants = `Type: Personne Physique, Nom: ${(companie.identite.entrepreneur && companie.identite.entrepreneur.descriptionPersonne && companie.identite.entrepreneur.descriptionPersonne.nom && companie.identite.entrepreneur.descriptionPersonne.prenoms) ? `${companie.identite.entrepreneur.descriptionPersonne.nom} ${companie.identite.entrepreneur.descriptionPersonne.prenoms.join(',')}` : "Nom inconnu"}`;
        } else if (companie.typePersonne === "M") {
            if (companie.composition && companie.composition.pouvoirs && companie.composition.pouvoirs.length) {
                representants = companie.composition.pouvoirs.map((item, i) => {
                    if (item.typeDePersonne === "ENTREPRISE") {
                        return `Type: Personne Morale, Dénomination: ${item.entreprise.denomination}${(item.roleEntreprise && leadersRoles[item.roleEntreprise]) ? `, Qualité: ${leadersRoles[item.roleEntreprise]}` : ""}${i + 1 !== companie.composition.pouvoirs.length
                            ? ` / `
                            : ""
                            }`
                    } else {
                        return `Type: Personne Physique, Nom: ${(item.individu && item.individu.descriptionPersonne && item.individu.descriptionPersonne.nom && item.individu.descriptionPersonne.prenoms) ? `${item.individu.descriptionPersonne.nom} ${item.individu.descriptionPersonne.prenoms.join(',')}` : "Inconnu"}${(item.roleEntreprise && leadersRoles[item.roleEntreprise]) ? `, Qualité: ${leadersRoles[item.roleEntreprise]}` : ""}${i + 1 !== companie.composition.pouvoirs.length
                            ? ` / `
                            : ""
                            }`
                    }
                }).join("");
            } else {
                representants = ""
            }
        }
        /*if (companie.identite) {
            if (companie.identite.personnePhysique) {
                representants = `Type: Personne Physique, Nom: ${companie.identite.personnePhysique.identite}`;
            } else if (companie.identite.personneMorale) {
                if (companie.representants && companie.representants.length) {
                    representants = companie.representants
                        .map((item, index) => {
                            if (item.type === "P.Morale") {
                                return `Type: Personne Morale, Dénomination: ${item.identite.toUpperCase()}${item.qualite
                                    ? `, Qualité : ${item.qualite}`
                                    : ""
                                    }${index + 1 !== companie.representants.length
                                        ? ` / `
                                        : ""
                                    }`;
                            } else {
                                return `Type: Personne Physique, Nom: ${item.identite.toUpperCase()} ${item.qualite
                                    ? `, Qualité : ${item.qualite}`
                                    : ""
                                    }${index + 1 !== companie.representants.length
                                        ? ` / `
                                        : ""
                                    }`;
                            }
                        })
                        .join("");
                } else {
                    representants = "";
                }
            }
        }*/
        return {
            representants: representants
        };
    };


    getRnes = async () => {
        /**
         * @instance
         * @async
         * @method getRnes
         * @memberof Containers.CustomComponent
         * @description Download XLSX file
         * @return { Void }
         */
        const { companies } = this.props.consumers;
        const etablissements = await companies.getLocalCompanies();
        const sirens = etablissements.map((item: any) => item.siren);
        const rnes = await companies.getRnes(sirens);
        const mergedEtablissements = etablissements.map((item: any) => {
            const entreprise = rnes.find((elm) => elm.siren === item.siren);
            const { representants } = entreprise
                ? this.refactorInpiData(entreprise)
                : { representants: "" };
            return {
                ...item,
                representants: representants,
            };
        });
        await companies.setLocalCompanies(mergedEtablissements);
        this.download();
        this.handleModalClose();
    };

    handleModalOpen = () => {
        /**
         * @instance
         * @async
         * @method handleModalOpen
         * @memberof Containers.CustomComponent
         * @description Open confirmation modal
         * @return { Void }
         */
        this.getRnes();
        this.setState({ modal: true });
    };

    handleModalClose = () => {
        /**
         * @instance
         * @async
         * @method handleModalClose
         * @memberof Containers.CustomComponent
         * @description Close confirmation modal
         * @return { Void }
         */
        this.setState({ modal: false });
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Xlsx
         * @return { String } JSX
         */
        const { modal } = this.state;
        return (
            <Fragment>
                <Modal open={modal} centered={false}>
                    <div className="modal-loader">
                        <Loader active inline="centered" size="large">
                            Préparation du fichier en cours
                        </Loader>
                    </div>
                    <Modal.Content>
                        <Container textAlign="center">
                            <Button onClick={this.handleModalClose}>
                                Annuler
                            </Button>
                        </Container>{" "}
                    </Modal.Content>
                </Modal>
                <Menu.Item
                    disabled={this.props.disabled}
                    onClick={this.handleModalOpen}
                >
                    {this.props.children}
                </Menu.Item>
            </Fragment>
        );
    }
}
export { XlsxItem };
