const leadersRoles = {
    "11": "Membre",
    "13": "Contrôleur de gestion",
    "14": "Contrôleur des comptes",
    "23": "Autre associé majoritaire",
    "28": "Gérant et associé indéfiniment et solidairement responsable",
    "29": "Gérant et associé indéfiniment responsable",
    "30": "Gérant",
    "40": "Liquidateur",
    "41": "Associé unique (qui participe à l'activité EURL)",
    "51": "Président du conseil d'administration",
    "52": "Président du directoire",
    "53": "Directeur Général",
    "55": "Dirigeant à l'étranger d'une personne morale étrangère",
    "56": "Dirigeant en France d'une personne morale étrangère",
    "60": "Président du conseil d'administration et directeur général",
    "61": "Président du conseil de surveillance",
    "63": "Membre du directoire",
    "64": "Membre du conseil de surveillance",
    "65": "Administrateur",
    "66": "Personne ayant le pouvoir d'engager à titre habituel la société",
    "67": "Personne ayant le pouvoir d'engager l'établissement",
    "69": "Directeur général unique de SA à directoire",
    "70": "Directeur général délégué",
    "71": "Commissaire aux comptes titulaire",
    "72": "Commissaire aux comptes suppléant",
    "73": "Président de SAS",
    "74": "Associé indéfiniment et solidairement responsable",
    "75": "Associé indéfiniment responsable",
    "76": "Représentant social d'une entreprise personne étrangère sans établissement en France",
    "77": "Représentant fiscal d'une entreprise personne étrangère sans établissement en France",
    "82": "Indivisaire",
    "86": "Exploitant pour le compte de l'indivision",
    "90": "Personne physique, exploitant en commun",
    "97": "Mandataire ad hoc",
    "98": "Administrateur provisoire",
    "99": "Autre",
    "94": "Membre non salarié participant aux travaux",
    "95": "Associé qui participe à la gestion",
    "96": "Associé non salarié",
    "100": "Repreneur",
    "101": "Entrepreneur",
    "103": "Suppléant",
    "104": "Personne chargée du contrôle",
    "105": "Personne décisionnaire désignée",
    "106": "Comptable",
    "107": "Héritier indivisaire",
    "108": "Loueur",
    "109": "Mandataire fiscal",
    "110": "Vice-Président",
    "111": "Vice-Président du Directoire",
    "120": "Vice-Président du Conseil d'Orientation et de Surveillance",
    "121": "Président du Conseil d'Orientation et de Surveillance",
    "122": "Membre du Conseil d'Orientation et de Surveillance",
    "130": "Associé unique qui récupère le patrimoine",
    "131": "Associé commandité",
    "132": "Associé commanditaire",
    "140": "Président de l'EPIC",
    "150": "Avocat",
    "200": "Fiduciaire",
    "201": "Dirigeant",
    "202": "Représentant de l'assujetti unique",
    "203": "Membre bénéficiant d'un mandat général d'administration",
    "204": "Personne capable d'engager l'entité",
    "205": "Président",
    "206": "Directeur",
    "209": "Secrétaire général",
    "210": "Membre du conseil syndical",
    "211": "Président du conseil syndical",
    "207": "Trésorier",
    "208": "Secrétaire",
    "212": "Personne désignée par les statuts"
}

export { leadersRoles}