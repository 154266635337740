// @flow
import React from "react";
import { I8S } from "services/i8sService";
import { Geolocate } from "services/geolocateService";
import { Knowledge } from "services/knowledgeService";
import { LocalStorage } from "services/storageService";
import { getCurrentPosition, isEmptyObject, arrayFirst, chunks } from "tools";

const CompaniesContext = React.createContext < any > ();

type Props = {
    children: any,
};

type State = {
    radius: number,
};

/**
 * CompaniesProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Companies provider
 */
class CompaniesProvider extends React.Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            radius: 1000,
        };
    }

    getPosition = async () => {
        /**
         * @instance
         * @async
         * @method getPosition
         * @memberof Providers.CompaniesProvider
         * @return { Object } User position
         */
        try {
            const result = await LocalStorage.getItemAsJSON("position");
            if (isEmptyObject(result)) {
                const { coords } = await getCurrentPosition();
                const response = await Geolocate.reverseGeocode(
                    `${coords.latitude},${coords.longitude}`
                );
                const data = await response.json();
                const adresse = arrayFirst(data.results);
                const position = {
                    adresse: adresse.formatted_address,
                    coords: {
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                    },
                };
                return await this.setPosition(position);
            } else {
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    setPosition = async (position: any) => {
        /**
         * @instance
         * @async
         * @method setPosition
         * @memberof Providers.CompaniesProvider
         * @param { Object } position User position
         * @return { Object } Save to localstorage user position
         */
        try {
            return await LocalStorage.setItemAsJSON("position", position);
        } catch (error) {
            throw error;
        }
    };

    findPosition = async (adresse: string) => {
        /**
         * @instance
         * @async
         * @method findPosition
         * @memberof Providers.CompaniesProvider
         * @param { String } adresse User adresse
         * @return { Array } List of adresses
         */
        try {
            const response = await Geolocate.geocode(adresse);
            const data = await response.json();
            return data.results;
        } catch (error) {
            throw error;
        }
    };

    getCompany = async (siret: number) => {
        /**
         * @instance
         * @async
         * @method getCompany
         * @memberof Providers.CompaniesProvider
         * @param { Numner } siret Siret
         * @return { Object } Company
         */
        try {
            const response = await I8S.getEtablissement(siret);
            const payload = await response.json();
            return payload;
        } catch (error) {
            throw error;
        }
    };

    getRne = async (siren: any) => {
        /**
         * @instance
         * @async
         * @method getRne
         * @memberof Providers.CompaniesProvider
         * @param { String } siren Siren
         * @return { Object } Company
         */
        try {
            const response = await I8S.getEntreprise(siren);
            const payload = await response.json();
            return payload;
        } catch (error) {
            throw error;
        }
    };

    getRnes = async (sirens: Array<string>) => {
        /**
         * @instance
         * @async
         * @method getRnes
         * @memberof Providers.CompaniesProvider
         * @param { Array } sirens Sirens
         * @return { Array } Companies
         */
        try {
            let payload = [];
            const partiels = chunks(sirens, 50);
            for (let part of partiels) {
                let statusCode = 0;
                while (
                    statusCode !== 200 &&
                    statusCode !== 404 &&
                    statusCode !== 400
                ) {
                    try {
                        const response = await I8S.getEntreprises(part);
                        const entreprises = await response.json();
                        statusCode = response.status;
                        payload = [...payload, ...entreprises];
                    } catch (error) {
                        if (error.status) {
                            statusCode = error.status;
                        }
                    }
                }
            }
            return payload;
        } catch (error) {
            throw error;
        }
    };

    getCompanies = async (extend: boolean = false) => {
        /**
         * @instance
         * @async
         * @method getCompanies
         * @memberof Providers.CompaniesProvider
         * @param { Boolean } extend Reaserch by Code Ape
         * @return { Object } Companies
         */
        try {
            let response;
            const { range, total } = await this.getParams();
            const radius = await this.getRadius();
            const { coords } = await this.getPosition();
            const { idcc } = await LocalStorage.getItemAsJSON("ccn");
            if (!extend) {
                response = await I8S.getEtablissementsByIdcc(
                    idcc,
                    range,
                    coords,
                    radius
                );
            } else {
                response = await I8S.getEtablissementsByApe(
                    idcc,
                    range,
                    coords,
                    radius
                );
            }
            const payloadEtablissements = await response.json();

            const keyFigures = await I8S.getKeyFigures(
                payloadEtablissements.etablissements.map((item) => item.siren)
            );
            const payloadkeyFigures = await keyFigures.json();
            const knowledge = await Knowledge.getKnowledgeBySiret(
                payloadEtablissements.etablissements.map((item) => item.siret)
            );
            const payloadknowledge = await knowledge.json();

            const companies = payloadEtablissements.etablissements
                .map((item) => {
                    let b = payloadknowledge.find(
                        (e) => e.siret === item.siret
                    );
                    if (b) return { ...item, knowledge: b };
                    return item;
                })
                .map((item) => {
                    let b = payloadkeyFigures.find(
                        (e) => e.siren === item.siren
                    );
                    if (b) return { ...item, keyFigures: b };
                    return item;
                });

            const localCompanies = await this.getLocalCompanies();
            const mergeCompanies = [...localCompanies, ...companies];
            await this.setParams({
                total: payloadEtablissements.total
                    ? payloadEtablissements.total
                    : total,
                current: mergeCompanies.length,
            });
            await this.setLocalCompanies(mergeCompanies);
            return mergeCompanies;
        } catch (error) {
            throw error;
        }
    };

    getLocalCompanies = async () => {
        /**
         * @instance
         * @async
         * @method getLocalCompanies
         * @memberof Providers.CompaniesProvider
         * @return { Object } Company
         */
        const LocalCompanies = await LocalStorage.getItemAsJSON("companies");
        const companies = !isEmptyObject(LocalCompanies) ? LocalCompanies : [];
        return companies;
    };

    updateCompanies = (elements: any, value: string, data: any) =>
        elements.map((element) =>
            /**
             * @instance
             * @async
             * @method updateCompanies
             * @memberof Providers.CompaniesProvider
             * @description Update companies
             * @return { Void }
             */
            element.siret === value
                ? {
                    ...element,
                    ...{
                        knowledge: {
                            emails: data.emails,
                            phone: data.phone,
                            website: data.website,
                            socials: data.socials,
                        },
                    },
                }
                : element
        );

    setLocalCompanies = async (data: any) => {
        /**
         * @instance
         * @async
         * @method getLocalCompanies
         * @memberof Providers.CompaniesProvider
         * @param { Object } data Company
         * @return { Void }
         */
        return await LocalStorage.setItemAsJSON("companies", data);
    };

    getKnowledge = async (e: string, a: string, s: string) => {
        /**
         * @instance
         * @async
         * @method getKnowledge
         * @memberof Providers.CompaniesProvider
         * @param { String } query Company name and address
         * @return { Object } Knowledge
         */
        try {
            const response = await Knowledge.getKnowledge(e, a, s);
            const payload = await response.json();
            return payload;
        } catch (error) {
            throw error;
        }
    };

    getRadius = async () => {
        /**
         * @instance
         * @async
         * @method getRadius
         * @memberof Providers.CompaniesProvider
         * @return { Number} Radius
         */
        const localRadius = await LocalStorage.getItem("radius");
        const radius = localRadius ? Number(localRadius) : this.state.radius;
        return radius;
    };

    setRadius = async (radius: number) => {
        /**
         * @instance
         * @async
         * @method setRadius
         * @memberof Providers.CompaniesProvider
         * @param { Number } radius Radius
         * @return { Void } Radius
         */
        await LocalStorage.setItem("radius", radius * 1000);
    };

    getParams = async () => {
        /**
         * @instance
         * @async
         * @method getParams
         * @memberof Providers.CompaniesProvider
         * @return { Object } Params
         */
        return await LocalStorage.getItemAsJSON("params");
    };

    setParams = async (params: any) => {
        /**
         * @instance
         * @async
         * @method setParams
         * @memberof Providers.CompaniesProvider
         * @params { Object } params Current params
         * @return { Void } Params
         */
        const local = await this.getParams();
        await LocalStorage.setItemAsJSON("params", { ...local, ...params });
    };

    clear = async (items: any) => {
        /**
         * @instance
         * @async
         * @method clear
         * @memberof Providers.CompaniesProvider
         * @param { Array } items Items
         * @return { Void }
         */
        await LocalStorage.removeItems(items);
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.CompaniesProvider
         * @return { String } JSX
         */
        return (
            <CompaniesContext.Provider
                value={{
                    ...this.state,
                    getPosition: this.getPosition,
                    setPosition: this.setPosition,
                    findPosition: this.findPosition,
                    getCompany: this.getCompany,
                    getRne: this.getRne,
                    getRnes: this.getRnes,
                    getCompanies: this.getCompanies,
                    getLocalCompanies: this.getLocalCompanies,
                    setLocalCompanies: this.setLocalCompanies,
                    updateCompanies: this.updateCompanies,
                    getKnowledge: this.getKnowledge,
                    getRadius: this.getRadius,
                    setRadius: this.setRadius,
                    getParams: this.getParams,
                    setParams: this.setParams,
                    clear: this.clear,
                }}
            >
                {this.props.children}
            </CompaniesContext.Provider>
        );
    }
}

const CompaniesConsumer = CompaniesContext.Consumer;

export { CompaniesProvider, CompaniesConsumer };
